"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'UserProfile',
    props: {
        user: null,
        company: null,
        isAuth: { type: Boolean }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var __returned__ = {};
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
