<div class="w-100 bg-home"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="col-12 col-sm-6 bg-secondary bg-opacity-50"><div class="w-100 p-5"><div class="d-flex flex-direction-row align-items-end mb-2 pb-1 border-bottom border-info"><img class="mx-1" src="/img/abc-logo-192.png" width="60"><h1 class="fs-3 fw-bold text-white p-0 m-0">Always Be Connecting</h1></div><p class="subtitle-home">The purpose of Always Be Connecting is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div><div class="col-12 col-sm-6"><log-in-form></log-in-form></div></div></div></div><div class="w-100 py-5"><h2 class="m-auto w-100 text-center mb-5">Members</h2><div class="bg-light p-2"><div class="row"><div class="col-sm-12"><Carousel :settings="carouselSettings" :breakpoints="carouselBreakpoints"><Slide v-for="profile,index in profiles" :key="index"><div class="card py-2 member-card w-100 m-2 bg-secondary"><img class="rounded-circle m-auto company-logo" :src="profile.company.logo || '/img/abc-logo-192.png'" :alt="profile.company.name + ' logo'"><h3 class="fw-normal text-light fs-5"> {{profile.company.name}}</h3><p class="text-light fs-6">{{profile.company.industry}}</p><p><a class="btn btn-primary" :href="`/profile/${profile.representatives[0]?.id}`">View details &raquo;</a></p></div></Slide></Carousel></div></div></div></div><div class="w-100 py-5 bg-white"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="col-12 col-sm-6 h-100"><div class="w-100 p-5"><h1 class="title-home-black"><strong>The purpose of Always Be Connecting</strong></h1><p class="text-home">The purpose of Always Be Connecting is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div><div class="col-12 col-sm-6"><img class="w-100" src="/img/business-meet.webp" data-no-webp-src="/img/business-meet.jpg" alt="Always Be Connecting"></div></div></div></div><div class="w-100 py-5 bg-white"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="col-12 col-sm-6"><img class="w-100" src="/img/vision.webp" data-no-webp-src="/img/vision.jpg" alt="Always Be Connecting"></div><div class="col-12 col-sm-6"><div class="w-100 p-5"><h1 class="title-home-black"><strong>The vision of Always Be Connecting</strong></h1><p class="text-home">The purpose of Always Be Connecting is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div></div></div></div><div class="companyRow d-sm-flex align-items-center justify-content-center"><div class="container"><div class="row"><p class="text-center">Powered by<a href="mailto:info@velansolutions.com"><img src="/img/velan-logo.webp" data-no-webp-src="/img/velan-logo.png" alt="Velan Solution" width="40" height="40"></a><a class="text-white" href="https://velansolutions.com" rel="noopener" target="_blank">V&eacute;lan Solutions LLC</a></p><p class="text-center">v {{ version }}</p></div></div></div>